/**
 * Created by zhaoyue on 16/11/28.
 */

import { app as api } from '@ekuaibao/whispered'
import { getBacklogInfoById, getSearchFlow } from './expense-manage-action'
import { getBacklogInfoById as otherGetBacklogInfoById, getSearchFlow as otherGetSearchFlow } from './receiptManage/receipt-manage-action'
export default [
  {
    id: '@expense-manage',
    reducer: () => import('./expense-manage-reducer'),
    path: '/expense-manage',
    ref: '/',
    onload: () => import('./expense-manage-view'),
    'get:backlog:info:byId'(params) {
      return api.dispatch(getBacklogInfoById(params))
    },
    'search:flow:by:filter'({ params, scene, dimensionItems, privilegeId, noFormType = false, showExpense,otherParams }) {
      return api.dispatch(getSearchFlow(params, scene, dimensionItems, privilegeId, noFormType, showExpense,otherParams))
    }
  },
  {
    path: '/checking-manage',
    ref: '/',
    onload: () => import('./expense-manage-view'),
  },
  {
    path: '/settle-manage',
    ref: '/',
    onload: () => import('./expense-manage-view'),
  },
  {
    path: '/expense-manage-privilege/:id',
    ref: '/',
    onload: () => import('./expense-manage-view')
  },
  {
    id: '@receipt-manage',
    reducer: () => import('./receiptManage/receipt-manage-reducer'),
    path: '/receipt-manage',
    ref: '/',
    onload: () => import('./receiptManage/receipt-manage-view'),
    'get:backlog:info:byId'(params) {
      return api.dispatch(otherGetBacklogInfoById(params))
    },
    'search:flow:by:filter'({ params, scene, dimensionItems }) {
      return api.dispatch(otherGetSearchFlow(params, scene, dimensionItems))
    }
  },
  {
    path: '/receipt-manage-privilege/:id',
    ref: '/',
    onload: () => import('./receiptManage/receipt-manage-view')
  }
]
