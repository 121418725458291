/**
 * Created by zhaoyue on 16/11/28.
 */

export const ID = '@expense-manage'

export default {
  ID: ID,
  GET_EXPENSE_SPECIFICATIONS: `${ID}/GET_EXPENSE_SPECIFICATIONS`,
  GET_SEARCH_FLOW: `${ID}/GET_SEARCH_FLOW`,
  GET_BACKLOG_INFO_BY_ID: `${ID}/GET_BACKLOG_INFO_BY_ID`,
  GET_DIMS: `${ID}/GET_DIMS`,
  GET_PRINT_TEMPLATE_LIST: `${ID}/GET_PRINT_TEMPLATE_LIST`
}
