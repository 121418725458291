/**
 * Created by zhaoyue on 16/11/28.
 */
import { QuerySelect } from 'ekbc-query-builder'
import { app as api } from '@ekuaibao/whispered'
import key from './key'
import { Resource } from '@ekuaibao/fetch'
const flows = new Resource('/api/flow/v1/flows')
import parseQuery2Select from '@ekuaibao/lib/lib/parseQuery2Select'
import parseSelectUsePropertySet from '@ekuaibao/lib/lib/parseSelectUsePropertySet'
import { Fetch } from '@ekuaibao/fetch'
function getPropertySet() {
  return api.dataLoader('@common.globalFields').load()
}

//获取激活流程
export function getSearchFlow(params, scene, dimensionItems, privilegeId, noFormType = false, showExpense,otherParams) {
  const filtersFixer = api.require('@lib/filtersFixer')
  let { status, otherFilters = [], formType } = params
  status = {
    state: [
      'archived',
      'paid',
      'paying',
      'approving',
      'sending',
      'receiving',
      'receivingExcep',
      'rejected',
      'nullify'
    ],
    ...status
  }
  params = { ...params, status }
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  let query = parseQuery2Select(params, undefined, 'flow', lang)
  if (otherFilters.length) {
    otherFilters.forEach((filter) => {
      query = query.filterBy(filter)
    })
  }
  //排除对账单
  let join = {
    join$1: 'id,nodeState,/flow/v2/backlogs/state'
  }
  if (formType === 'checking') {
    query = query.filterBy(`INSTR(form.specificationId,\"${Fetch.ekbCorpId}:system:对账单\")>0`)
    join.formType = 'expense'
    join.join$2 = "id,checkingInfo,/v2/checking/detail/$checking"
  } else if (formType === 'settle') {
    query = query.filterBy("form.systemGenerationSettle == true")
    join.join$2 = "id,checkingInfo,/v2/checking/detail/$settle"
  } else if (privilegeId) {
    join.privilegeId = privilegeId
  } else if(showExpense) {
    join.formType = 'expense'
  } else if (!noFormType) {
    join.formType = 'expense'
    query = query.filterBy(`INSTR(form.specificationId,\"${Fetch.ekbCorpId}:system:对账单\")==0`)
  }
  if (!otherParams){
    otherParams = {}
  }
  return {
    type: key.GET_SEARCH_FLOW,
    payload: getPropertySet().then((resp) => {
      const content = parseSelectUsePropertySet(resp.data)
      const sceneFiltersQuery = scene ? filtersFixer(scene, 'flow', dimensionItems) : ''
      query.select(`ownerId(id,name),form(${content},...),...`).filterBy(sceneFiltersQuery)
      const queryString = query.value()
      return flows.POST('/search', queryString, { ...join,...otherParams })
    })
  }
}

//获取审批流程详情
export function getBacklogInfoById(id, privilegeId) {
  const { fetchAttachment, fetchInvoice } = api.require('@lib/attachment-fetch')
  const { selectEntity, joinParams } = api.require('@lib/entity.join')
  const fetchMutilValue = api.require('@lib/mutil-staff-fetch')

  const query = new QuerySelect().filterBy(`id=="${id}"`).select(`${selectEntity()}`).value()
  const params = {
    id,
    privilegeId,
    flowManager: true,
    ...joinParams()
  }
  return {
    type: key.GET_BACKLOG_INFO_BY_ID,
    payload: flows
      .POST('/flowId/$id', query, params)
      .then(fetchAttachment)
      .then(fetchInvoice)
      .then(fetchMutilValue)
  }
}
